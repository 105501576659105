/**
 * Modal
 * ========
 *
 * An _extremely_ simple modal class. We simply listen for a button click,
 * display the modal, and close when we're told. Nothing else.
 *
 * ## Usage
 * All that's needed to create a new modal is a button that has the attribute
 * `data-for-modal` with the ID of the modal. E.g.
 *
 *     <button data-for-modal="my-modal">Click here</button>
 *     <div id="my-modal"><!-- Modal Contents Here --></div>
 *
 */
class Modal {
    button;
    window;
    constructor(button) {
        this.button = button;
        const modalSelector = button.getAttribute("data-for-modal");
        if (!modalSelector) {
            throw new Error(`No selector given for modal button "${button.id || button.className || button.tagName}"`);
        }
        // Do some accessibility stuff while we're here
        button.setAttribute("aria-controls", modalSelector);
        this.window = document.getElementById(modalSelector);
        if (!this.window) {
            console.error(`No modal window found for selector "${modalSelector}"`);
            return;
        }
        this.init();
    }
    init() {
        this.close();
        this.button.addEventListener("click", this.open);
        const closeBtns = this.window.querySelectorAll(".modal-close");
        for (const closeBtn of closeBtns) {
            closeBtn.addEventListener("click", this.close);
        }
        if (this.window.classList.contains("modal-backdrop")) {
            this.window.addEventListener("click", (e) => {
                if (e.target === this.window) {
                    this.close();
                }
            });
        }
        document.documentElement.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.close();
            }
        });
    }
    close = () => {
        this.window.style.display = "none";
        this.window.setAttribute("aria-expanded", "false");
    };
    open = () => {
        this.window.style.display = "";
        // Force repaint so we can animate things
        this.window.getBoundingClientRect();
        this.window.setAttribute("aria-expanded", "true");
    };
}
export function init() {
    const modalBtns = Array.from(document.querySelectorAll("[data-for-modal]"));
    return modalBtns.map((btn) => new Modal(btn));
}
