/**
 * Tooltip
 * ========
 *
 * A tooltip that shows a window on hover or focus
 *
 */
export function init() {
    const tooltips = Array.from(document.querySelectorAll("[data-tooltip]"));
    for (const tooltip of tooltips) {
        const tooltipSelector = tooltip.getAttribute("data-tooltip");
        if (!tooltipSelector) {
            continue;
        }
        const tooltipWindow = document.getElementById(tooltipSelector);
        if (!tooltipWindow) {
            continue;
        }
        const showTip = () => {
            tooltipWindow.style.display = "";
            tooltipWindow.classList.add("open");
        };
        const hideTip = () => {
            tooltipWindow.style.display = "none";
            tooltipWindow.classList.remove("open");
        };
        tooltip.addEventListener("mouseenter", showTip);
        tooltip.addEventListener("focus", showTip);
        tooltip.addEventListener("mouseleave", hideTip);
        tooltip.addEventListener("blur", hideTip);
        hideTip();
    }
}
