/**
 * Availability Pickers
 * ========
 *
 * This code controls the JS pieces of the availability picker. Primarily this
 * includes the showing and hiding of certain blocks of times based on what
 * days are selected.
 *
 * ## Usage
 * To use, create an element with the .availability-picker class. That element
 * should have the following types of children
 *
 * - `.availability-picker-day-input` - Input elements for days
 * - `.times-of-day-block` - For each day, a set of times that can be chosen
 * - `.availability-times` - Wrapper around all of the time blocks for general
 *   showing and hiding if all time blocks are hidden
 *
 * The `.times-of-day-block` should have an attribute `data-day` which denotes
 * which day (input value) it corresponds with
 *
 */
export function init() {
    const pickers = document.getElementsByClassName("availability-picker");
    for (const picker of pickers) {
        // The checkboxes for the availability days
        const dayToggles = picker.getElementsByClassName("availability-picker-day-input");
        // The group of available time checkboxes for each day
        const timeOfDayBlocks = Array.from(picker.getElementsByClassName("times-of-day-block"));
        // A wrapper around all of the time blocks to hide if all blocks are hidden
        const wholeTimeBlock = Array.from(picker.getElementsByClassName("availability-times"))[0];
        // The time-of-day-block associated with the given day
        const getTimeOfDayBlock = (day) => timeOfDayBlocks.find((b) => b.getAttribute("data-day") === day);
        const getTimeOfDayCheckboxes = (block) => Array.from(block.querySelectorAll("input[type=checkbox]"));
        for (const toggle of dayToggles) {
            const day = toggle.value;
            // Bascially a redraw for the current state
            const updateState = (alterSelection = false) => {
                const block = getTimeOfDayBlock(day);
                if (!block) {
                    return;
                }
                block.style.display = toggle.checked ? "" : "none";
                // When we select a new day, we want to have all the time options on
                // by default. So check them all
                if (toggle.checked && alterSelection) {
                    getTimeOfDayCheckboxes(block).forEach((box) => {
                        box.checked = true;
                        const changeNotice = new Event("change");
                        box.dispatchEvent(changeNotice);
                    });
                }
                else if (!toggle.checked && alterSelection) {
                    getTimeOfDayCheckboxes(block).forEach((box) => {
                        box.checked = false;
                        const changeNotice = new Event("change");
                        box.dispatchEvent(changeNotice);
                    });
                }
                // If all the blocks are hidden, hide the wrapper
                if (timeOfDayBlocks.every((b) => b.style.display === "none")) {
                    wholeTimeBlock.style.display = "none";
                }
                else {
                    wholeTimeBlock.style.display = "";
                }
            };
            toggle.addEventListener("change", () => updateState(true));
            updateState(false);
        }
    }
}
