/**
 * Submit Button
 * ========
 *
 * A submit button that provides feedback to the user on click
 *
 * ## Usage
 * Any button that has `type="submit"` set will get this behavior
 * added to it automatically
 *
 */
export function init() {
    const submitBtns = Array.from(document.querySelectorAll('button[type="submit"]'));
    for (const btn of submitBtns) {
        btn.addEventListener("click", () => {
            // Disable the button (deferred one tick so we don't mess prevent submit)
            setTimeout(() => btn.setAttribute("disabled", "disabled"), 0);
            // Get the dimensions so the button doesn't resize when we replace text
            const btnDimens = btn.getBoundingClientRect();
            btn.style.minWidth = `${btnDimens.width}px`;
            btn.style.minHeight = `${btnDimens.height}px`;
            // Replace text with spinner icon
            const loader = document.createElement("span");
            loader.classList.add("loading-icon");
            loader.setAttribute("aria-label", "Submitting...");
            btn.innerHTML = "";
            btn.appendChild(loader);
        });
    }
}
