/**
 * Schedule Carousel
 * ========
 *
 * A special carousel component for the scheduling page. Used to
 * display a set of days that can be booked, with each being a
 * button to show the appointments for that day.
 *
 */
// When animating scroll, have a buffer for when we hide the
// Left/Right buttons so it doesn't look weird at the end.
const SCROLL_BUTTON_BUFFER = 15;
// How many items are considered to be "shown" in the scroll
// window at a time
const ITEMS_SHOWN = 4;
let SUPPORTS_SMOOTH_SCROLL = false;
(function actuallySupportsSmoothScroll() {
    const parent = document.createElement("div");
    const child = document.createElement("div");
    child.style.height = "500px";
    child.style.width = "500px";
    parent.style.overflow = "hidden";
    parent.style.height = "1px";
    parent.style.width = "1px";
    parent.appendChild(child);
    document.body.appendChild(parent);
    parent.scroll({ left: 10, behavior: "smooth" });
    setTimeout(() => {
        SUPPORTS_SMOOTH_SCROLL = parent.scrollLeft != 0;
        parent.remove();
    }, 50);
})();
export function init() {
    const carousels = document.getElementsByClassName("schedule-carousel");
    for (const carousel of carousels) {
        // Get the buttons to go to the next "page"
        const nextBtns = Array.from(carousel.getElementsByClassName("next-btn"));
        // Get the buttons to go to the previous "page"
        const prevBtns = Array.from(carousel.getElementsByClassName("prev-btn"));
        // Get our scroll window for the carousel. There is only one of these
        const scrollWindow = carousel.getElementsByClassName("scroll-window")[0];
        // Get all of the items that can change the schedule shown
        const items = Array.from(carousel.parentElement.querySelectorAll("[data-schedule-for-day]"));
        // Get all the appointment schedules we should show
        const dayBlocks = Array.from(carousel.parentElement.querySelectorAll("[data-schedule-day]"));
        //////// Helper functions
        const hideBtns = (set) => {
            set.forEach((btn) => (btn.style.display = "none"));
        };
        const showBtns = (set) => {
            set.forEach((btn) => (btn.style.display = ""));
        };
        // Based on the current scroll position, show or hide left/right arrows
        const updateBtnState = () => {
            // Force redraw so positions are accurate
            scrollWindow.getBoundingClientRect();
            if (scrollWindow.scrollLeft === 0 || // on initial load, we always want to show the scroll right/later button
                scrollWindow.scrollWidth - SCROLL_BUTTON_BUFFER >
                    scrollWindow.clientWidth + scrollWindow.scrollLeft) {
                showBtns(nextBtns);
            }
            else {
                hideBtns(nextBtns);
            }
            if (scrollWindow.scrollLeft > SCROLL_BUTTON_BUFFER) {
                showBtns(prevBtns);
            }
            else {
                hideBtns(prevBtns);
            }
        };
        // Find the first item showing in the scroll window
        const findViewportFirstItemIndex = () => {
            return items.findIndex((item) => item.offsetLeft >= scrollWindow.scrollLeft);
        };
        // Scroll the window to a given item in the list
        const scrollToItem = (item) => {
            const opts = {};
            // This should never actually await
            if (SUPPORTS_SMOOTH_SCROLL) {
                opts["behavior"] = "smooth";
            }
            const scrollTo = items.findIndex((i) => i === item);
            if (items.length - scrollTo <= ITEMS_SHOWN * 2) {
                // Scroll to end
                opts.left = scrollWindow.scrollWidth - scrollWindow.clientWidth;
            }
            else if (scrollTo === 0) {
                opts.left = 0;
            }
            else {
                // Add buffer for the previous button
                opts.left = item.offsetLeft - 40;
            }
            scrollWindow.scroll(opts);
        };
        // Next page
        const goNext = () => {
            const currIndex = findViewportFirstItemIndex();
            scrollToItem(items.length <= currIndex + ITEMS_SHOWN
                ? items[items.length - 1]
                : items[currIndex + ITEMS_SHOWN]);
        };
        // Previous page
        const goPrev = () => {
            const currIndex = findViewportFirstItemIndex();
            scrollToItem(currIndex <= ITEMS_SHOWN ? items[0] : items[currIndex - ITEMS_SHOWN]);
        };
        // Actually select a day and show the block for that day
        const selectItem = (forDay) => {
            // Grab the button in case something else is causing us to select an item
            const selectedBtn = scrollWindow.querySelector(`[data-schedule-for-day="${forDay}"]`);
            // Show/hide time blocks for days
            dayBlocks.forEach((block) => {
                if (block.getAttribute("data-schedule-day") === forDay) {
                    block.style.display = "";
                }
                else {
                    block.style.display = "none";
                }
            });
            // Mark button selected
            items.forEach((i) => i.classList[i.getAttribute("data-schedule-for-day") === forDay ? "add" : "remove"]("selected"));
            scrollToItem(selectedBtn);
        };
        // Use scroll to update buttons so we don't have to inject this
        // call everywhere and can also animate scroll with minimal impact
        scrollWindow.addEventListener("scroll", updateBtnState);
        nextBtns.forEach((btn) => btn.addEventListener("click", goNext));
        prevBtns.forEach((btn) => btn.addEventListener("click", goPrev));
        items.forEach((item) => item.addEventListener("click", (e) => selectItem(e.currentTarget.getAttribute("data-schedule-for-day"))));
        // Initial state: First item is selected and we are scrolled left
        scrollWindow.scroll({ left: 0 });
        updateBtnState();
        selectItem(items[0].getAttribute("data-schedule-for-day"));
    }
}
