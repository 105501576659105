import { isVisible } from "../utilities/visibility";
/**
 * Select All Button
 * ========
 *
 * A button that selects all sibiling checkboxes, or deselects them if
 * they are already selected. This also updates the text of a button to
 * show "Select all" if not everything is checked, or "Clear all" if
 * everything is.
 *
 * ## Usage
 * Make an element with a `data-select-all` attribute. Place this item
 * alongside the inputs you want to control as a sibling element.
 *
 */
export function init() {
    const selectAllBtns = document.querySelectorAll("[data-select-all]");
    for (const btn of selectAllBtns) {
        const parent = btn.parentNode;
        if (!parent) {
            // This would only happen if we're not attached to the page. This
            // should be impossible, but makes Typescript happy.
            continue;
        }
        // Grab sibling checkboxes
        const inputs = Array.from(parent.querySelectorAll("input[type=checkbox]"));
        // For select/deselect, we only want to apply changes to shown items
        const getVisibleInputs = () => inputs.filter((i) => isVisible(i) || isVisible(i.parentElement));
        /** @return boolean if all of the visible checkboxes are checked */
        const allSelected = () => getVisibleInputs().every((i) => i.checked);
        /** Update the button text based on if everything is checked */
        const updateBtnText = () => {
            if (allSelected()) {
                btn.textContent = "Clear all";
            }
            else {
                btn.textContent = "Select all";
            }
        };
        btn.addEventListener("click", () => {
            const check = !allSelected();
            getVisibleInputs().forEach((i) => {
                i.checked = check;
                const changeNotice = new Event("change");
                i.dispatchEvent(changeNotice);
            });
            updateBtnText();
        });
        inputs.forEach((i) => {
            i.addEventListener("change", updateBtnText);
        });
        updateBtnText();
        // If the set of visible checkboxes changes, we want to recheck everything
        const watcher = new MutationObserver(updateBtnText);
        watcher.observe(parent, { attributes: true, subtree: true });
    }
}
