"use strict";
[
    require("./utilities/sticky-stuck-class"),
    require("./components/select-all-btn"),
    require("./components/clear-all-btn"),
    require("./components/availability-picker"),
    require("./components/dropdown"),
    require("./components/modal"),
    require("./components/page-back-btn"),
    require("./components/schedule-carousel"),
    require("./components/tooltip"),
    require("./components/submit-btn"),
].forEach((module) => module.init());
