/**
 * Clear All Button
 * ========
 *
 * A button that clears a set of input checkboxes or radios.
 *
 * ## Usage
 * Make an element with a `data-clear-all` attribute. The value of this
 * attribute is the CSS-style selectors for the checkboxes or radios you
 * want to clear.
 *
 */
export function init() {
    const clearAllBtns = document.querySelectorAll("[data-clear-all]");
    for (const btn of clearAllBtns) {
        const clearAllSelector = btn.getAttribute("data-clear-all");
        if (!clearAllSelector) {
            continue;
        }
        const inputs = Array.from(document.querySelectorAll(clearAllSelector));
        // For clearing, we only want to apply changes to shown items
        const getVisibleInputs = () => inputs.filter((i) => i.offsetParent || i.parentElement?.offsetParent);
        btn.addEventListener("click", () => {
            getVisibleInputs().forEach((i) => {
                i.checked = false;
                const changeNotice = new Event("change");
                i.dispatchEvent(changeNotice);
            });
        });
    }
}
