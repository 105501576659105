/**
 * Page Back Button
 * ========
 *
 * A button that goes back in history if it makes sense to
 *
 * ## Usage
 * Simply use the .page-back class
 *
 */
export function init() {
    const backBtns = Array.from(document.getElementsByClassName("page-back"));
    for (const btn of backBtns) {
        btn.addEventListener("click", (e) => {
            const linkTo = btn.href;
            const [_, uuid] = location.pathname.match(/^\/([a-f\-0-9]+)\//) || [];
            let goBackTo;
            if (document.referrer?.includes(linkTo) ||
                // HACK: This is a very hacky and ugly way to solve this problem, but
                // we want to go back to the UUID instead of search so we get the
                // cache benefits
                (uuid &&
                    document.referrer.match(new RegExp(`/${uuid}/?$`)) &&
                    linkTo.match(new RegExp(`/${uuid}/search$`)) &&
                    !location.search.includes("preferred_provider="))) {
                goBackTo = document.referrer;
            }
            if (goBackTo) {
                location.assign(goBackTo);
                e.preventDefault();
                e.stopPropagation();
            }
        });
    }
}
