/**
 * Sticky Stuck Class
 * ========
 *
 * This is a utility to add a "stuck" class whenever an item
 * is in sticky-mode. This allows styling based on when an
 * element gets "stuck".
 *
 * Some elements may stick to the top of the bottom of it's
 * parent scroll container, so we have two different varieties
 * but both are functionally identitcal. Each uses a `data-*`
 * attribute with the value being an Id that we should listen
 * for scroll events on.
 *
 * If the containing element does not have a scroll bar, we
 * *do not* consider the element as "stuck". It is just a part
 * of flow at that point.
 *
 * ## Usage
 * Add either the `data-stuck-to-bottom` or `data-stuck-to-top`
 * attributes to the "sticky" element. The value should be the
 * Id of a contain we should listen for scrolls for. This does
 * not need to be a parent and can be any element.
 *
 * Once added, whenever it is "stuck", the element will get the
 * `.stuck` class added. You can use this to style the element.
 *
 */
export function init() {
    const stickyElementsBottom = document.querySelectorAll("[data-stuck-to-bottom]");
    const stickyElementsTop = document.querySelectorAll("[data-stuck-to-top]");
    // Scroll handlers can be perf bottlenecks, so we want to group any sticky
    // listeners that point to the same element.
    const scrollHanlders = new Map();
    // Convenience function to add an element to the right handler
    const addToMap = (stuckEl, bottomOrTop) => {
        const scrollSelector = stuckEl.getAttribute(`data-stuck-to-${bottomOrTop}`);
        if (!scrollSelector) {
            return;
        }
        const scrollContainer = document.getElementById(scrollSelector);
        if (!scrollContainer) {
            // Just console log this for now
            console.warn("Unable to find scroll container", scrollSelector);
            return;
        }
        if (!scrollHanlders.has(scrollContainer)) {
            scrollHanlders.set(scrollContainer, { top: [], bottom: [] });
        }
        const handlers = scrollHanlders.get(scrollContainer);
        handlers[bottomOrTop].push(stuckEl);
    };
    stickyElementsBottom.forEach((el) => addToMap(el, "bottom"));
    stickyElementsTop.forEach((el) => addToMap(el, "top"));
    // Once we've got all the sticky elements grouped, add listeners
    for (const [scroller, handlers] of scrollHanlders.entries()) {
        const { top, bottom } = handlers;
        const updateStuck = () => {
            if (Math.floor(scroller.scrollTop) > 0) {
                top.forEach((el) => el.classList.add("stuck"));
            }
            else {
                top.forEach((el) => el.classList.remove("stuck"));
            }
            if (Math.abs(scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop) < 2) {
                bottom.forEach((el) => el.classList.remove("stuck"));
            }
            else {
                bottom.forEach((el) => el.classList.add("stuck"));
            }
        };
        scroller.addEventListener("scroll", updateStuck);
        updateStuck();
        const observer = new MutationObserver(() => {
            updateStuck();
        });
        // Listen for things that might change the size of the element in question
        observer.observe(scroller, {
            attributes: true,
            childList: true,
            subtree: true,
        });
    }
}
