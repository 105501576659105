/**
 * Dropdown
 * ========
 *
 * An _extremely_ simple dropdown class. We simply listen for a button click
 * and toggle the dropdown window. We also close the dropdown if it is open
 * when a person clicks anywhere else on the page.
 *
 * ## Usage
 * All that's needed to create a new dropdown is a button that has the attribute
 * `data-for-dropdown` with the ID of the dropdown window. E.g.
 *
 *     <button data-for-dropdown="my-dropdown">Click here</button>
 *     <div id="my-dropdown"><!-- Modal Contents Here --></div>
 *
 */
class Dropdown {
    element;
    window;
    constructor(element) {
        this.element = element;
        const selector = element.getAttribute("data-for-dropdown");
        if (!selector) {
            throw new Error(`No selector given for this.element button "${element.id || element.className || element.tagName}"`);
        }
        this.window = document.getElementById(selector);
        if (!this.window) {
            console.error(`No this.element window found for selector "${selector}"`);
            return;
        }
        this.init();
    }
    get isOpen() {
        return this.window.style.display !== "none";
    }
    init() {
        this.element.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.toggle();
        });
        Array.from(this.window.getElementsByClassName("dialog-close")).forEach((closeBtn) => closeBtn.addEventListener("click", (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.toggle(true);
        }));
        this.window.style.display = "none";
        document.documentElement.addEventListener("click", (e) => {
            if (!this.isOpen) {
                return;
            }
            if (e.target !== this.element &&
                e.target !== this.window &&
                !this.window.contains(e.target) &&
                !this.element.contains(e.target)) {
                // Close it
                this.toggle();
            }
        });
    }
    toggle = (forceClose = false) => {
        const closing = forceClose || this.isOpen;
        this.window.style.display = closing ? "none" : "";
        // Force a repaint on the window so that any animations will work
        this.window.getBoundingClientRect();
        this.element.classList[closing ? "remove" : "add"]("open");
        this.window.classList[closing ? "remove" : "add"]("open");
    };
}
export function init() {
    const dropdowns = Array.from(document.querySelectorAll("[data-for-dropdown]"));
    return dropdowns.map((el) => new Dropdown(el));
}
